import type { Ref } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

import type { SportlineCatalogClickComposable } from 'web/src/modules/sportline/composables/catalog';
import type { Region } from 'web/src/modules/sportline/types';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { useSportlineCacheStorage } from 'web/src/modules/sportline/submodules/cache';

interface UseRegionHeadlineProps {
  regionId: Ref<string>;
  customTitle: Ref<Maybe<string>>;
  isComing: Ref<boolean>;
}

interface UseRegionHeadlineComposable extends SportlineCatalogClickComposable {
  title: Ref<Maybe<string>>;
}

export function useRegionHeadline(
  props: UseRegionHeadlineProps,
): UseRegionHeadlineComposable {
  const { regionId, customTitle, isComing } = props;

  const { $translate } = useI18n();
  const cacheStore = useSportlineCacheStorage();
  const sportlineCatalogClick = useSportlineCatalogClick({ regionId });

  const regionCache = computed(() => cacheStore.getRegionCache(regionId.value).value);
  const region = computed<Maybe<Region>>(() => regionCache.value?.region ?? null);

  const title = computed<Maybe<string>>(() => {
    if (customTitle.value) {
      return customTitle.value;
    }
    if (isComing.value) {
      return $translate('WEB2_COMING_EVENTS').value;
    }
    if (region.value) {
      return region.value.name;
    }
    return null;
  });

  return {
    ...sportlineCatalogClick,
    title,
  };
}
