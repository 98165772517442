import { type Ref, toRef } from 'vue';
import { ref } from 'vue';

import { PostMessageBus } from '@leon-hub/postmessage-bus';

import type { AdditionalQueryStringParameters, VIframeRef } from 'web/src/components/Iframe/VIframe/types';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import {
  TwitterTimelinePostMessageBusInitiator,
  TwitterTimelinePostMessageEvent,
} from 'web/src/modules/framed-app/components/TwitterWidget/utils';
import { useCybersportSidebarStore } from 'web/src/modules/sportline/submodules/cybersport/store';

interface UseCybersportSidebarIFrameComposable {
  iframeWidgetSrc: Ref<Optional<string>>;
  timelineIFrame: Ref<Optional<VIframeRef>>;
  additionalQueryStringParameters: Ref<AdditionalQueryStringParameters>;
  iframeWidgetName: Ref<string>;
  isTimelineReady: Ref<boolean>;
  initPostMessageBus(): void;
}

export function useCybersportSidebarIFrame(): UseCybersportSidebarIFrameComposable {
  const cybersportSidebarStore = useCybersportSidebarStore();
  const iframeWidgetName = toRef(() => cybersportSidebarStore.iframeWidgetName);
  const iframeWidgetSrc = toRef(() => cybersportSidebarStore.iframeWidgetSrc);
  const additionalQueryStringParameters = toRef(() => cybersportSidebarStore.additionalQueryStringParameters);

  const timelineIFrame = ref<VIframeRef>();
  const isTimelineReady = ref(false);

  let postMessageBus: PostMessageBus | undefined;

  function initPostMessageBus(): void {
    postMessageBus?.dispose();

    const iframeContentWindow = requireContentWindowByIFrameName(iframeWidgetName.value);

    postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: TwitterTimelinePostMessageBusInitiator,
    });

    postMessageBus.on(TwitterTimelinePostMessageEvent.rendered, () => {
      isTimelineReady.value = true;
    });
  }

  return {
    iframeWidgetSrc,
    iframeWidgetName,
    additionalQueryStringParameters,
    timelineIFrame,
    isTimelineReady,
    initPostMessageBus,
  };
}
