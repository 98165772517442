<script setup lang="ts">
import { toRef } from 'vue';

import type { SportElementHeadlineExpandState, SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import type { BasePrimaryHeadlineEmits, BasePrimaryHeadlineProps } from './types';
import PrimaryHeadline from './PrimaryHeadline.vue';
import { useRegionHeadline } from './useRegionHeadline';

interface RegionHeadlineProps extends BasePrimaryHeadlineProps {
  regionId: string;
  customTitle?: Maybe<string>;
  basisKey: SportlineFragmentBasisKey;
}

const props = defineProps<RegionHeadlineProps>();
const emit = defineEmits<BasePrimaryHeadlineEmits>();

const regionId = toRef(props, 'regionId');
const customTitle = toRef(props, 'customTitle', null);
const isComing = toRef(props, 'isComing', false);
const expandKey = toRef(props, 'expandKey', null);
const isExpanded = toRef(props, 'isExpanded', false);
const countEvents = toRef(props, 'countEvents', 0);
const hideEventsCounter = toRef(props, 'hideEventsCounter', false);
const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);

const {
  title,
  onCatalogClick,
} = useRegionHeadline({ regionId, customTitle, isComing });

function toggleExpandState(payload: SportElementHeadlineExpandState): void {
  emit('toggle-expand-state', payload);
}
</script>

<template>
  <PrimaryHeadline v-auto-id="'RegionHeadline'"
    :title="title"
    :expand-key="expandKey"
    :is-expanded="isExpanded"
    :count-events="countEvents"
    :hide-events-counter="hideEventsCounter"
    :is-coming="isComing"
    :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
    :basis-key="basisKey"
    @toggle-expand-state="toggleExpandState"
    @catalog-click="onCatalogClick"
  />
</template>
