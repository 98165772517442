import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRefs } from 'vue';

import type { GenericSegmentPageComposable } from 'web/src/modules/sportline/submodules/segment/composables/types';
import type { RegionsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import type {
  RegionElement,
  RegionElementNoEventsProperties,
  SportElement,
} from 'web/src/modules/sportline/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { resolveCybersportPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import {
  useSegmentPageFilters,
  useSegmentPageResetFiltersHooks,
  useSegmentPageSeoMeta,
} from 'web/src/modules/sportline/submodules/segment/composables';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';
import { SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';

interface CybersportSegmentPageComposable
  extends GenericSegmentPageComposable<RegionsSegmentPageListGroup> {
  sportElement: Ref<Maybe<SportElement>>;
  getEmptyEventsComponentProperties(group?: RegionsSegmentPageListGroup): RegionElementNoEventsProperties;
}

export default function useCybersportSegmentPage(): CybersportSegmentPageComposable {
  const cybersportStore = useSportlineCybersportStore();
  const {
    isReady,
    totalCount,
    selectedFilter,
    isFilterTransitionFadeEffectEnabled,
    sportElement,
    isFavoritesTabAvailable,
    filterTransitionDuration,
    liveDisciplinesMap,
    comingDisciplinesMap,
    disciplinesList,
    compiledComingDisciplinesList,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    fullEventsResponse,
    customerStreamsDisciplinesList,
  } = toRefs(cybersportStore);
  const {
    setBackgroundUpdateEnabled,
  } = cybersportStore;

  const mainGroups = computed<RegionsSegmentPageListGroup[]>(() => {
    const selectedKey = selectedFilter.value;
    const liveRegions = liveDisciplinesMap.value;
    const comingRegions = comingDisciplinesMap.value;
    return (disciplinesList.value ?? [])
      .map((regionElement: RegionElement): RegionsSegmentPageListGroup => {
        const transitionId = regionElement.region.navigationParameters.urlName.toLowerCase();
        const key = `${regionElement.region.id}_${transitionId}`;
        const liveElement: Optional<RegionElement> = Object.values(liveRegions)
          .find((element: RegionElement) => element.region.id === regionElement.region.id);
        const prematchElement: Optional<RegionElement> = Object.values(comingRegions)
          .find((element: RegionElement) => element.region.id === regionElement.region.id);

        return {
          filterElement: regionElement,
          liveElement,
          prematchElement,
          transitionId,
          allowedForTransitionIds: [transitionId],
          isActive: selectedKey === transitionId,
          isEmpty: !liveElement?.counters.live,
          isEmptyComing: !prematchElement?.counters.prematch,
          key,
          type: SegmentPageTabType.Default,
        };
      });
  });
  const allDisciplinesGroups = computed<RegionsSegmentPageListGroup[]>(() => {
    const disciplines = compiledComingDisciplinesList.value ?? [];

    if (disciplines.length === 0) {
      return [];
    }

    const selectedKey = selectedFilter.value;

    return [{
      transitionId: CustomFilter.AllEvents,
      allowedForTransitionIds: [CustomFilter.AllEvents],
      isActive: selectedKey as CustomFilter === CustomFilter.AllEvents,
      isEmpty: false,
      isEmptyComing: true,
      showComingName: true,
      key: 'all-disciplines-cybersport',
      type: SegmentPageTabType.All,
    }];
  });
  const favoriteGroups = computed<RegionsSegmentPageListGroup[]>(() => {
    const selectedKey = selectedFilter.value;
    const eventsIds = customerOptimisticFavoriteSportEventsIds.value;
    const leaguesIds = customerOptimisticFavoriteLeaguesIds.value;
    const response = fullEventsResponse.value;
    const hasFavorites = SportEventsResponseChangeUtils.isSomeIdsInList(response, { eventsIds, leaguesIds });

    return hasFavorites
      ? [{
          transitionId: CustomFilter.Favorites,
          allowedForTransitionIds: [CustomFilter.Favorites],
          isActive: selectedKey as CustomFilter === CustomFilter.Favorites,
          isEmpty: false,
          isEmptyComing: true,
          key: 'favorite-cybersport',
          type: SegmentPageTabType.Favorite,
          showComingName: true,
        }]
      : [];
  });
  const streamEventsGroups = computed<RegionsSegmentPageListGroup[]>(() => {
    const disciplines = customerStreamsDisciplinesList.value || [];

    if (disciplines.length === 0) {
      return [];
    }

    const selectedKey = selectedFilter.value;
    return disciplines
      .map((regionElement: RegionElement): RegionsSegmentPageListGroup => {
        const key = `stream-${regionElement.region.navigationParameters.urlName.toLowerCase()}`;
        return {
          filterElement: regionElement,
          liveElement: regionElement,
          transitionId: CustomFilter.Stream,
          allowedForTransitionIds: [CustomFilter.Stream],
          isActive: selectedKey as CustomFilter === CustomFilter.Stream,
          isEmpty: !regionElement.counters.live,
          isEmptyComing: true,
          key,
          type: SegmentPageTabType.Default,
        };
      })
      .filter((group: RegionsSegmentPageListGroup) => !group.isEmpty);
  });
  const groupsForDisplay = computed(() => [
    ...mainGroups.value,
    ...favoriteGroups.value,
    ...streamEventsGroups.value,
    ...allDisciplinesGroups.value,
  ]);
  // @TODO hasStreamEvents from store
  const hasStreamEvents = computed(() => streamEventsGroups.value.length > 0);

  const {
    allGroupForFilter,
    favoriteGroupForFilter,
    streamEventsGroupForFilter,
    activeTransitionId,
    setActiveTransitionId,
    canShowLiveForGroup,
    canShowPrematchForGroup,
  } = useSegmentPageFilters<RegionsSegmentPageListGroup>({
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    totalCount,
    selectedFilter,
    mainGroups,
    hasStreamEvents,
    isFavoritesTabAvailable,
  });

  function getEmptyEventsComponentProperties(group?: RegionsSegmentPageListGroup): RegionElementNoEventsProperties {
    const regionElement = group?.filterElement ?? group?.prematchElement;
    return regionElement ? { regionElement } : {};
  }

  const {
    listTransition,
    onChangeFilter,
    onActivatedFiltersHook,
    onDeactivatedFiltersHook,
  } = useSegmentPageResetFiltersHooks({
    isReady,
    selectedFilter,
    groupsForDisplay,
    persistentLocationKey: 'cybersport',
    setBackgroundUpdateEnabled,
    getDefaultPageLocation(): RouteLocationRaw {
      return resolveCybersportPageLink({});
    },
  });
  const {
    seoMetaInfo,
  } = useSegmentPageSeoMeta({
    isReady,
    activeSportElement: sportElement,
    activeRegionElement: computed(() => {
      const element = mainGroups.value.find((group) => group.isActive)?.filterElement;
      return element && ('region' in element) ? element : null;
    }),
    groupsForDisplay,
    canShowLiveForGroup,
    canShowPrematchForGroup,
  });

  return {
    isReady,
    totalCount,
    allGroupForFilter,
    mainGroups,
    groupsForDisplay,
    favoriteGroupForFilter,
    streamEventsGroupForFilter,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    seoMetaInfo,
    selectedFilter,
    activeTransitionId,
    setActiveTransitionId,
    canShowLiveForGroup,
    canShowPrematchForGroup,
    listTransition,
    onChangeFilter,
    onActivatedFiltersHook,
    onDeactivatedFiltersHook,
    getEmptyEventsComponentProperties,
    sportElement,
  };
}
