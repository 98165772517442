<script lang="ts" setup>
import { toRef } from 'vue';

import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import VLazyRender from 'web/src/components/LazyRender/VLazyRender/VLazyRender.vue';
import { LazyMainBannerSectionRouteComponent as MainBannerSectionRouteComponent } from 'web/src/modules/banners/components/MainBannerSectionRouteComponent';
import { BannerCategory, BannerLocation } from 'web/src/modules/banners/enums';
import SportlineComingHeader from 'web/src/modules/sportline/components/header/SportlineComingHeader.vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import CybersportNoEvents from 'web/src/modules/sportline/submodules/cybersport/components/CybersportNoEvents.vue';
import SportlineCybersportBlock from 'web/src/modules/sportline/submodules/cybersport/views/CybersportBlock/SportlineCybersportBlock.vue';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';

import type { CybersportRegionTapProps } from './types';
import { useCybersportRegionTab } from './useCybersportRegionTab';

const props = defineProps<CybersportRegionTapProps>();

const group = toRef(props, 'group');
const sportElement = toRef(props, 'sportElement');
const canShowPrematch = toRef(props, 'canShowPrematch', false);
const isOpen = toRef(props, 'isOpen', false);

const settingsStore = useSportlineSettingsStore();
const isMarketTypesSelectionEnabled = toRef(settingsStore, 'isMarketTypesSelectionEnabled');

const {
  liveGroup,
  prematchGroup,
} = useCybersportRegionTab({ group });
</script>

<template>
  <div v-auto-id="'CybersportDefaultTab'">
    <template v-if="liveGroup && sportElement">
      <SportlineCybersportBlock
        v-show="isOpen"
        :key="`${liveGroup.key}-live`"
        :custom-title="$t('WEB2_LIVE_SPORTLINE')"
        :region-element="liveGroup.liveElement"
        :sport-element="sportElement"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        collapse-league-key="default-cybersport-league"
      />
    </template>

    <CybersportNoEvents
      v-if="canShowPrematch && group.isEmpty"
      :key="`${group.key}-empty`"
      :custom-title="$t('WEB2_LIVE_SPORTLINE')"
      :region-element="group.filterElement ?? group.prematchElement"
    />

    <MainBannerSectionRouteComponent
      v-if="isOpen"
      :key="`${group.key}-banner`"
      is-static
      :location="BannerLocation.CUSTOM"
      :banner-type="BannerType.INLINE_BANNER"
      :custom-location-id="group.transitionId"
      :banner-category="BannerCategory.SUPER_LIVE"
    />

    <VLazyRender v-if="canShowPrematch && prematchGroup && sportElement">
      <SportlineComingHeader
        v-if="prematchGroup.doShowTitleComponent"
        :key="`${prematchGroup.key}-before-prematch`"
        :caption="$t('JS_SPORTS_QUICKFILTERS_ALL')"
      />

      <SportlineCybersportBlock
        :key="`${prematchGroup.key}-coming`"
        v-data-test="{ el: 'group-coming' }"
        :custom-title="$t('JS_SPORTS_QUICKFILTERS_ALL')"
        :region-element="prematchGroup.prematchElement"
        :sport-element="sportElement"
        :is-coming-headline="!prematchGroup.showComingName"
        :upcoming-label-type="UpcomingLabelType.Timer"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        collapse-league-key="default-cybersport-league"
      />
    </VLazyRender>
  </div>
</template>
