<script lang="ts" setup>
import {
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
} from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { VCover } from 'web/src/components/Cover';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import FilterTransition from 'web/src/modules/sportline/components/FilterTransition/FilterTransition.vue';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import CybersportNoEvents from 'web/src/modules/sportline/submodules/cybersport/components/CybersportNoEvents.vue';
import CybersportDefaultFilter from 'web/src/modules/sportline/submodules/cybersport/components/Filter/default/SportlineCybersportFilter.vue';
import useCybersportSegmentPage from 'web/src/modules/sportline/submodules/cybersport/composables/useCybersportSegmentPage';
import CybersportFavoritesTab from 'web/src/modules/sportline/submodules/cybersport/views/CybersportFavoritesTab.vue';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';

import CybersportBetSwipeAllTab from './all-tab/CybersportBetSwipeAllTab.vue';
import CybersportDefaultAllTab from './all-tab/CybersportDefaultAllTab.vue';
import { useCybersportNavigationSectionActivation } from './composables/useCybersportNavigationSectionActivation';
import CybersportBetSwipeTab from './region-tab/CybersportBetSwipeTab.vue';
import CybersportDefaultTab from './region-tab/CybersportDefaultTab.vue';

const {
  isReady,
  totalCount,
  allGroupForFilter,
  mainGroups,
  groupsForDisplay,
  favoriteGroupForFilter,
  streamEventsGroupForFilter,
  isFilterTransitionFadeEffectEnabled,
  filterTransitionDuration,
  seoMetaInfo,
  setActiveTransitionId,
  canShowLiveForGroup,
  canShowPrematchForGroup,
  listTransition,
  onChangeFilter,
  onActivatedFiltersHook,
  onDeactivatedFiltersHook,
  getEmptyEventsComponentProperties,
  sportElement,
} = useCybersportSegmentPage();

useSeoMetaParameters(seoMetaInfo);

const { isBetSwipeLiveLayoutEnabled } = useSportlineLayoutSettings();

onMounted(onActivatedFiltersHook);
onActivated(onActivatedFiltersHook);
onUnmounted(onDeactivatedFiltersHook);
onDeactivated(onDeactivatedFiltersHook);
useCybersportNavigationSectionActivation();

useKeepAlive([
  RouteName.HOME,
  RouteName.SPORTLINE_LIVE_EVENTS,
  RouteName.SPORTLINE_PREMATCH_EVENTS,
  RouteName.SPORT_REGION,
  RouteName.SPORT_LEAGUE,
  RouteName.SPORT_EVENT_DETAILS,
  RouteName.SPORTLINE_CYBERSPORT,
]);
</script>

<template>
  <div v-auto-id="'CybersportRoutePage'" class="component-wrapper">
    <FilterTransition
      v-if="isReady"
      ref="listTransition"
      key="sport-events-segment-page"
      :disabled="!isFilterTransitionFadeEffectEnabled"
      :fade-in-duration="filterTransitionDuration.fadeIn"
      :fade-out-duration="filterTransitionDuration.fadeOut"
      @change-id="setActiveTransitionId"
    >
      <template #filter="{ duration, afterEnter, afterLeave, beforeEnter, beforeLeave }">
        <div v-if="null && isBetSwipeLiveLayoutEnabled" />
        <CybersportDefaultFilter
          v-else
          :class="$style['events-list-filter']"
          :total-count="totalCount"
          :all-group="allGroupForFilter"
          :favorite-group="favoriteGroupForFilter"
          :stream-group="streamEventsGroupForFilter"
          :groups="mainGroups"
          @change-filter="onChangeFilter"
        >
          <template #afterItem="{ item }">
            <transition
              name="fade"
              :duration="duration"
              @before-leave="beforeLeave(item.transitionId)"
              @after-leave="afterLeave"
              @before-enter="beforeEnter(item.transitionId)"
              @after-enter="afterEnter"
            >
              <div
                v-if="item.isActive"
                :key="item.key"
              />
            </transition>
          </template>
        </CybersportDefaultFilter>
      </template>

      <template #content="{ isFadeOut, extraStyle }">
        <div
          v-show="!isFadeOut"
          key="sport-events-segment-content"
          :class="{
            [$style['events-list']]: true,
            [$style['events-list--extra-paddings']]: null && isBetSwipeLiveLayoutEnabled,
          }"
          :style="extraStyle"
        >
          <CybersportNoEvents
            v-if="totalCount === 0 && allGroupForFilter && canShowLiveForGroup(allGroupForFilter)"
            v-bind="getEmptyEventsComponentProperties(allGroupForFilter)"
            :key="`${allGroupForFilter.key}-empty`"
            :title="$t('WEB2_LIVE_NOEVENTS_TITLE')"
          />

          <VCover
            v-for="group in groupsForDisplay"
            :key="group.key"
            :opened="canShowLiveForGroup(group)"
          >
            <template #default="{ isLoaded, isOpen }">
              <CybersportFavoritesTab
                v-if="isLoaded && group.type === SegmentPageTabType.Favorite && isOpen"
                :key="`${group.key}-loaded`"
                v-data-test="{ el: 'favorites-tab', shown: isOpen || canShowPrematchForGroup(group) }"
                :is-active="isOpen"
              />
              <template v-else-if="isLoaded && group.type === SegmentPageTabType.All">
                <template v-if="null && isBetSwipeLiveLayoutEnabled">
                  <CybersportBetSwipeAllTab
                    v-if="sportElement && isOpen"
                    :key="`${group.key}-loaded`"
                    v-data-test="{ el: 'all-tab', shown: isOpen }"
                  />
                </template>
                <template v-else>
                  <CybersportDefaultAllTab
                    v-if="sportElement && isOpen"
                    :key="`${group.key}-loaded`"
                    v-data-test="{ el: 'all-tab', shown: isOpen }"
                  />
                </template>
              </template>
              <template v-else-if="isLoaded && group.type === SegmentPageTabType.Default">
                <CybersportBetSwipeTab
                  v-if="null && isBetSwipeLiveLayoutEnabled"
                  :key="`${group.key}-loaded`"
                  v-data-test="{ el: 'group-tab', shown: isOpen || canShowPrematchForGroup(group) }"
                  :sport-element="sportElement"
                  :group="group"
                  :is-open="isOpen"
                  :can-show-prematch="canShowPrematchForGroup(group)"
                />
                <CybersportDefaultTab
                  v-else
                  :key="`${group.key}-loaded`"
                  v-data-test="{ el: 'group-tab', shown: isOpen || canShowPrematchForGroup(group) }"
                  :sport-element="sportElement"
                  :group="group"
                  :is-open="isOpen"
                  :can-show-prematch="canShowPrematchForGroup(group)"
                />
              </template>
              <div
                v-else
                :key="`${group.key}-unloaded`"
              />
            </template>
          </VCover>
        </div>
      </template>
    </FilterTransition>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/sportline-page';

@include for-layout using ($isDesktop) {
  .events-list {
    position: relative;
    padding: if($isDesktop, 0, 0 8px);

    &--extra-paddings {
      @include sportline-page-paddings($isDesktop);
    }
  }

  .events-list-filter {
    margin: if($isDesktop, 0 0 8px, 0);

    &--extra-paddings {
      @include sportline-page-paddings($isDesktop);

      margin: 0 0 16px;
    }
  }
}
</style>
