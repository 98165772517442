import type { RouteLocationNormalized } from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';
import { AbstractPrefetch } from '@core/router';

import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

export default class CybersportPagePrefetch extends AbstractPrefetch {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const cybersportStore = useSportlineCybersportStore();
    const isPageEnabled = toRef(cybersportStore, 'isPageEnabled');
    const region = (to.params?.region as string | undefined)?.toLowerCase();

    if (!isPageEnabled.value) {
      return { name: RouteName.ERROR_NOT_FOUND_404 };
    }

    const navigationStore = useRootNavigationStore();

    const { setPersistLocation } = useSportlinePersistLocation();
    const navigationParameters = getSportlinePageNavigationParameters(to);
    setPersistLocation('cybersport', navigationParameters);

    if (!cybersportStore.isReady) {
      navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_CYBERSPORT);
      void cybersportStore.initialRequests().then(() => {
        cybersportStore.setSelectedFilter(region);
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_CYBERSPORT);
      });
    } else {
      cybersportStore.setSelectedFilter(region);
      cybersportStore.syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_CYBERSPORT);
    }

    return Promise.resolve();
  }
}
