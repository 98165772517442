<script lang="ts" setup>
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import DateTimeFilter from 'web/src/modules/sportline/components/date-time-filter/DateTimeFilter.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import LeaguesList from 'web/src/modules/sportline/submodules/sports-list/views/leagues-list/LeaguesList.vue';
import RegionSpoiler from 'web/src/modules/sportline/submodules/sports-list/views/regions-list/RegionSpoiler.vue';

import { useAllTabDateTimeFilter } from '../composables/useAllTabDateTimeFilter';
import { useCybersportAllTab } from '../composables/useCybersportAllTab';

const {
  setOpenRegionState,
  isRegionOpen,
  topLeagues,
  sportsTreeElement: sportElement,
  hasElementsForCurrentFilter,
} = useCybersportAllTab();
const {
  dateTimeFilterRef,
  isSportFiltersEnabled,
  currentDateTimeFilter,
  datetimeActiveKickoffPeriod,
  datetimeActiveSportlineType,
  datetimeCustomFrom,
  datetimeCustomTo,
  onDateTimeFilterSelect,
  onDateTimeFilterSelectCustomRange,
  resetDateTimeFilter,
} = useAllTabDateTimeFilter();
</script>

<template>
  <div v-auto-id="'CybersportDefaultAllTab'">
    <DateTimeFilter
      v-if="isSportFiltersEnabled"
      ref="dateTimeFilterRef"
      :class="$style['date-filter']"
      :active-kickoff-period="datetimeActiveKickoffPeriod"
      :active-sportline-type="datetimeActiveSportlineType"
      :selected-range-from="datetimeCustomFrom"
      :selected-range-to="datetimeCustomTo"
      :tabs-type="TabsType.BORDERED"
      @select-filter="onDateTimeFilterSelect"
      @select-range="onDateTimeFilterSelectCustomRange"
    />

    <LeaguesList
      v-if="sportElement && topLeagues.length > 0"
      :sport="sportElement.sport"
      :leagues="topLeagues"
      :date-time-filter="currentDateTimeFilter"
      is-top
    />

    <template v-if="sportElement && hasElementsForCurrentFilter">
      <div :class="$style['sport-header']">
        {{ $t('WEB2_ESPORT_GAMES_ALL_TITLE') }}
      </div>

      <div :class="$style['regions']">
        <RegionSpoiler
          v-for="regionElement in sportElement.regions"
          :key="`level1-spoiler-${regionElement.region.id}`"
          :sport-element="sportElement"
          :region-element="regionElement"
          :is-open="!!process.env.VUE_APP_PRERENDER || isRegionOpen(regionElement.region.id)"
          :date-time-filter="currentDateTimeFilter"
          is-cyber-sport-page
          @change-open-state="setOpenRegionState(regionElement.region.id, $event)"
        />
      </div>
    </template>
    <SportNoResults
      v-else
      can-reset-filter
      @reset="resetDateTimeFilter"
    />
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-spoiler';

.sport-header {
  @include medium\18\20;

  padding: 18px 0 18px 8px;
  color: var(--TextDefault);
  background-color: var(--Layer0);
}

.date-filter {
  background-color: var(--Layer0);
}

.regions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: stretch;
}
</style>
