import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import { SportlineNavigationSection } from 'web/src/modules/sportline/submodules/navigation/enums';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';

export function useCybersportNavigationSectionActivation(): void {
  const { setSportlineNavigationSection } = useSportlineNavigationStore();

  onComponentActivated(() => { setSportlineNavigationSection(SportlineNavigationSection.CyberSport); });
  onComponentDeactivated(() => { setSportlineNavigationSection(null); });
}
