import type { ComponentPublicInstance, Ref } from 'vue';
import { computed, ref } from 'vue';

import { useLifecycleResizeObserver } from '@leon-hub/vue-utils';

type ElementRef = HTMLElement | ComponentPublicInstance;

interface UseCybersportSidebarHeightComposable {
  sidebarRef: Ref<Optional<ElementRef>>;
  topLeaguesRef: Ref<Optional<ElementRef>>;
  spareHeight: Ref<number>;
}

function useLastElementHeight(elementRef: Ref<Optional<ElementRef>>): Ref<number> {
  const lastHeight = ref(0);

  function onResized(entries: ResizeObserverEntry[]): void {
    lastHeight.value = entries.reduce((sum, value) => (sum + value.contentRect.height), 0);
  }

  useLifecycleResizeObserver(elementRef, onResized);

  return lastHeight;
}

export function useCybersportSidebarHeight(): UseCybersportSidebarHeightComposable {
  const sidebarRef = ref<ElementRef>();
  const topLeaguesRef = ref<ElementRef>();

  const lastSidebarHeight = useLastElementHeight(sidebarRef);
  const lastTopLeaguesHeight = useLastElementHeight(topLeaguesRef);

  const spareHeight = computed(() => Math.max(lastSidebarHeight.value - lastTopLeaguesHeight.value - 16, 0));

  return {
    sidebarRef,
    topLeaguesRef,
    spareHeight,
  };
}
