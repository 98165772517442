import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type {
  UseSaveOpenRegionStateComposable,
} from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { extractTopLeagues, sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';

interface UseCybersportAllTabComposable extends UseSaveOpenRegionStateComposable {
  topLeagues: Ref<TopLeagueListElement[]>;
  sportsTreeElement: Ref<Maybe<SportsTreeSportElement>>;
  hasElementsForCurrentFilter: Ref<boolean>;
}

export function useCybersportAllTab(): UseCybersportAllTabComposable {
  const sportlineCybersportStore = useSportlineCybersportStore();

  const sportsTreeElement = toRef(sportlineCybersportStore, 'sportsTreeElement');

  const { setOpenRegionState, isRegionOpen } = sportlineCybersportStore;

  const topLeagues = computed<TopLeagueListElement[]>(() => (
    extractTopLeagues(sportsTreeElement.value).sort(sortTopLeagueElementsASC)
  ));
  const hasElementsForCurrentFilter = computed<boolean>(() => (
    !!sportsTreeElement.value?.regions?.length
  ));

  return {
    setOpenRegionState,
    isRegionOpen,
    topLeagues,
    sportsTreeElement,
    hasElementsForCurrentFilter,
  };
}
