<script lang="ts" setup>
import { computed, toRef } from 'vue';

import type {
  RegionElement,
  SportElement,
  SportId,
} from 'web/src/modules/sportline/types';
import type { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import {
  useSportlineElementMarketColumns,
  useSportlineElementMarketTypes,
} from 'web/src/modules/sportline/composables/markets';
import { extendedEventBlockCybersport } from 'web/src/modules/sportline/submodules/extended-event';
import RegionHeadline from 'web/src/modules/sportline/views/headline/RegionHeadline.vue';
import SportlineRegionBlock from 'web/src/modules/sportline/views/RegionBlock/SportlineRegionBlock.vue';

interface SportlineCybersportBlockProps {
  regionElement: RegionElement;
  sportElement: SportElement;
  withoutHeadline?: boolean;
  isComingHeadline?: boolean;
  upcomingLabelType?: Maybe<UpcomingLabelType>;
  isMarketTypesSelectionEnabled?: boolean;
  collapseLeagueKey?: Maybe<string>;
  canAddLeaguesToFavorite?: boolean;
  customTitle?: Maybe<string>;
}

interface SportlineCybersportBlockEmits {
  (e: 'click-event'): void;
}

const props = defineProps<SportlineCybersportBlockProps>();
const emit = defineEmits<SportlineCybersportBlockEmits>();

const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
const withoutHeadline = toRef(props, 'withoutHeadline', false);
const sportElement = toRef(props, 'sportElement');
const regionElement = toRef(props, 'regionElement');

const sportId = computed<SportId>(() => sportElement.value.sport.id);
const basisKey = createSportlineFragmentBasisKey({ sportId });
const { marketsColumns } = useSportlineElementMarketColumns({ sportlineElement: sportElement });
const { marketsTypes } = useSportlineElementMarketTypes({ sportlineElement: regionElement });

const {
  canSelectMarketType,
} = useProvideMarketColumn({
  basisKey,
  isMarketTypesSelectionEnabled,
  marketsColumns,
  marketsTypes,
});
</script>

<template>
  <div v-auto-id="'SportlineCybersportBlock'" v-if="regionElement.counters.live > 0 || regionElement.counters.prematch > 0">
    <RegionHeadline
      v-if="!withoutHeadline"
      :is-market-types-selection-enabled="canSelectMarketType"
      :custom-title="customTitle"
      :region-id="regionElement.region.id"
      :count-events="regionElement.counters.total"
      :is-coming="isComingHeadline"
      :basis-key="basisKey"
    />

    <SportlineRegionBlock
      :sport="sportElement.sport"
      :region-element="regionElement"
      :upcoming-label-type="upcomingLabelType"
      :can-add-to-favorite="!!'1'"
      :can-add-outrights-to-favorite="!!'1'"
      :collapse-league-key="collapseLeagueKey"
      :is-show-add-league-to-favorite="canAddLeaguesToFavorite"
      :extended-block-type="extendedEventBlockCybersport"
      :basis-key="basisKey"
      @click-event="emit('click-event')"
    />
  </div>
</template>
