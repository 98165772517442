<script lang="ts" setup>
import { ref, toRef } from 'vue';

import type {
  MarketsColumn,
  MarketType,
  RegionElement,
  RegionElementNoEventsProperties,
} from 'web/src/modules/sportline/types';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import RegionHeadline from 'web/src/modules/sportline/views/headline/RegionHeadline.vue';

interface CybersportNoEventsProps extends RegionElementNoEventsProperties {
  regionElement?: RegionElement;
  isMarketTypesSelectionEnabled?: boolean;
  customTitle?: Maybe<string>;
}

interface CybersportNoEventsSlots {
  title(props: Dictionary<never>): void;
}

const props = defineProps<CybersportNoEventsProps>();
defineSlots<CybersportNoEventsSlots>();

const basisKey = createSportlineFragmentBasisKey({});

useProvideMarketColumn({
  basisKey,
  isMarketTypesSelectionEnabled: toRef(props, 'isMarketTypesSelectionEnabled'),
  marketsColumns: ref<MarketsColumn[]>([]),
  marketsTypes: ref<MarketType[]>([]),
});
</script>

<template>
  <div v-auto-id="'CybersportNoEvents'">
    <slot name="title">
      <RegionHeadline
        v-if="regionElement"
        :region-id="regionElement.region.id"
        :custom-title="customTitle"
        :basis-key="basisKey"
        hide-events-counter
      />
    </slot>

    <div :class="$style['no-events-content']">
      <div :class="$style['no-events-content__label']">
        {{ $t('WEB2_LIVE_NOEVENTS_TITLE') }}
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/no-events';
</style>
