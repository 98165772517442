<script lang="ts" setup>
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import { VSidebar } from 'web/src/components/Sidebar';
import SidebarCaption from 'web/src/components/SidebarMenu/SidebarCaption/SidebarCaption.vue';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import AllLeaguesLink from 'web/src/modules/sportline/submodules/sidebar/components/all-leagues-link/AllLeaguesLink.vue';
import TopLeaguesList from 'web/src/modules/sportline/submodules/sidebar/components/leagues-list/TopLeaguesList.vue';
import { useSidebarClick } from 'web/src/modules/sportline/submodules/sidebar/composables';

import { useCybersportSidebarStore } from '../store';
import {
  useCybersportSidebarHeight,
  useCybersportSidebarIFrame,
  useCybersportSidebarTabs,
} from './composables';

const { catalogClick } = useSidebarClick();

const cybersportSidebarStore = useCybersportSidebarStore();
const { setBackgroundUpdateEnabled } = cybersportSidebarStore;

const {
  iframeWidgetSrc,
  iframeWidgetName,
  additionalQueryStringParameters,
  timelineIFrame,
  isTimelineReady,
  initPostMessageBus,
} = useCybersportSidebarIFrame();
const {
  sidebarRef,
  topLeaguesRef,
  spareHeight,
} = useCybersportSidebarHeight();
const {
  maxLeaguesInTab,
  topLeaguesList,
  tabs,
  activeTab,
  onActivateTabClick,
} = useCybersportSidebarTabs();

onComponentActivated(() => { void setBackgroundUpdateEnabled(true); });
onComponentDeactivated(() => { void setBackgroundUpdateEnabled(false); });
</script>

<template>
  <VSidebar v-auto-id="'CybersportSidebarRouteComponent'"
    ref="sidebarRef"
    :class="$style['cybersport-sidebar']"
  >
    <div
      v-if="topLeaguesList.length > 0"
      ref="topLeaguesRef"
      :class="$style['cybersport-sidebar__top-leagues']"
    >
      <SidebarCaption :title="$t('WEB2_TOP_LEAGUES')" />

      <VTabs
        v-if="tabs.length > 1"
        :class="$style['cybersport-sidebar__tabs']"
        :type="TabsType.SMALL"
        :items="tabs"
        :active-id="activeTab?.key"
        is-bordered
        @tab-click="onActivateTabClick"
      />

      <TopLeaguesList
        :list="topLeaguesList"
        @level-click="catalogClick($event)"
      />

      <AllLeaguesLink
        v-if="activeTab && activeTab.totalCount > maxLeaguesInTab"
        :page-location="activeTab.allLeaguesLocation"
        :title="activeTab.key === CustomFilter.MyFavoriteLeagues ? $t('JSP_MY_LEAGUES') : $t('WEB2_ALL_LEAGUES')"
        :count="activeTab.totalCount"
      />
    </div>

    <div
      v-if="iframeWidgetSrc"
      :class="$style['cybersport-sidebar__timeline-holder']"
    >
      <VLoaderMaskedDelayed
        v-if="!isTimelineReady"
      />
      <VIframe
        ref="timelineIFrame"
        :class="$style['cybersport-sidebar__timeline']"
        :style="{ 'min-height': `${spareHeight}px` }"
        :src="iframeWidgetSrc"
        :name="iframeWidgetName"
        :additional-query-string-parameters="additionalQueryStringParameters"
        flex-layout
        @vue:mounted="initPostMessageBus"
      />
    </div>
  </VSidebar>
</template>

<style module lang="scss">
.cybersport-sidebar {
  min-height: calc(var(--device-unit-100vh) - $headerDesktopHeight - 8px);
  margin: 0 0 -40px;

  &__tabs {
    padding: 0 4px;
    margin-bottom: 4px;
  }

  &__top-leagues {
    margin-bottom: 16px;
  }

  &__timeline-holder {
    position: relative;
    width: 100%;
  }

  &__timeline {
    position: absolute;
    width: 100%;
  }
}
</style>
