import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { TabsItem } from 'web/src/components/Tabs';
import type {
  ActiveTopLeagueListElement,
  FilterTab,
  SidebarFilterTabId,
} from 'web/src/modules/sportline/submodules/sidebar/types';
import { useSportlineAnalytics } from 'web/src/modules/sportline/composables/analytics';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useCybersportSidebarStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { useInnerActiveTabId } from 'web/src/modules/sportline/submodules/sidebar/composables';
import {
  createFilterTabForFavoriteLeagues,
  createFilterTabForSportElement,
  createFilterTabForZeroMargin,
} from 'web/src/modules/sportline/submodules/sidebar/utils';

interface UseCybersportSidebarTabsComposable {
  tabs: Ref<TabsItem[]>;
  activeTab: Ref<Maybe<FilterTab>>;
  topLeaguesList: Ref<ActiveTopLeagueListElement[]>;
  maxLeaguesInTab: Ref<number>;
  onActivateTabClick(to: SidebarFilterTabId): void;
}

export function useCybersportSidebarTabs(): UseCybersportSidebarTabsComposable {
  const cybersportSidebarStore = useCybersportSidebarStore();
  const maxTabsCount = toRef(() => cybersportSidebarStore.maxTabsCount);
  const maxLeaguesInTab = toRef(() => cybersportSidebarStore.maxLeaguesInTab);
  const fullCybersportTree = toRef(() => cybersportSidebarStore.fullCybersportTree);
  const fullFavoriteLeagues = toRef(() => cybersportSidebarStore.fullFavoriteLeagues);

  const analytics = useSportlineAnalytics();
  const { innerActiveTabId, setInnerActiveTab } = useInnerActiveTabId();

  const filterTabs = computed<FilterTab[]>(() => {
    const result: FilterTab[] = [];

    const sportElements = fullCybersportTree.value ?? [];

    const isActiveFavoriteLeagues = innerActiveTabId.value === CustomFilter.MyFavoriteLeagues;
    const favoriteLeaguesTab = createFilterTabForFavoriteLeagues(fullFavoriteLeagues.value ?? [], isActiveFavoriteLeagues);
    if (favoriteLeaguesTab) { result.push(favoriteLeaguesTab); }

    const isActiveZeroMargin = innerActiveTabId.value === CustomFilter.ZeroMargin;
    const zeroMarginTab = createFilterTabForZeroMargin(sportElements, isActiveZeroMargin);
    if (zeroMarginTab) { result.push(zeroMarginTab); }

    for (const [index, sportElement] of sportElements.entries()) {
      const isActiveElement = innerActiveTabId.value ? sportElement.sport.id === innerActiveTabId.value : index === 0;
      const tab = createFilterTabForSportElement(sportElement, isActiveElement);
      if (tab) { result.push(tab); }
    }

    return result;
  });

  const tabs = computed<TabsItem[]>(() => filterTabs.value
    .map((filterTab: FilterTab): TabsItem => filterTab.tabItem)
    .slice(0, maxTabsCount.value));

  const activeTab = computed<Maybe<FilterTab>>(() => (
    filterTabs.value.find((tab) => tab.isActive)
    ?? filterTabs.value[0]
    ?? null
  ));

  const topLeaguesList = computed<ActiveTopLeagueListElement[]>(() => (
    (activeTab.value?.leagues ?? []).slice(0, maxLeaguesInTab.value)
  ));

  return {
    tabs,
    activeTab,
    maxLeaguesInTab,
    topLeaguesList,
    onActivateTabClick(to: SidebarFilterTabId): void {
      setInnerActiveTab(to);

      if (to as CustomFilter === CustomFilter.ZeroMargin) {
        analytics.zeroMarginSidebarTabClick();
      }
    },
  };
}
