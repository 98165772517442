import type { Ref } from 'vue';
import { ref, toRef } from 'vue';

import type { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { DateTimeFilterReference } from 'web/src/modules/sportline/components/date-time-filter/types';
import type { SportlineType } from 'web/src/modules/sportline/enums';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { DateTimeRange, SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { useSportsListDateFilter } from 'web/src/modules/sportline/submodules/sports-list/components/composables';

interface UseAllTabDateTimeFilterComposable {
  dateTimeFilterRef: Ref<Optional<DateTimeFilterReference>>;
  isSportFiltersEnabled: Ref<boolean>;
  currentDateTimeFilter: Ref<SportDateTimeFilter>;
  datetimeActiveKickoffPeriod: Ref<SportFilterKickoffPeriod>;
  datetimeActiveSportlineType: Ref<Optional<SportlineType>>;
  datetimeCustomFrom: Ref<Optional<number>>;
  datetimeCustomTo: Ref<Optional<number>>;
  onDateTimeFilterSelect(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void>;
  onDateTimeFilterSelectCustomRange(range: DateTimeRange): Promise<void>;
  resetDateTimeFilter(): Promise<void>;
}

export function useAllTabDateTimeFilter(): UseAllTabDateTimeFilterComposable {
  const sportlineSettingsStore = useSportlineSettingsStore();
  const isSportFiltersEnabled = toRef(sportlineSettingsStore, 'isSportFiltersEnabled');

  const sportlineCybersportStore = useSportlineCybersportStore();
  const currentDateTimeFilter = toRef(sportlineCybersportStore, 'sportsTreeDateTimeFilter');
  const { reloadSportsTreeForFilter } = sportlineCybersportStore;

  const dateTimeFilterRef = ref<Optional<DateTimeFilterReference>>();

  const {
    dateTimeFilterCustomFrom: datetimeCustomFrom,
    dateTimeFilterCustomTo: datetimeCustomTo,
    dateTimeFilterActiveKickoffPeriod: datetimeActiveKickoffPeriod,
    dateTimeFilterActiveSportlineType: datetimeActiveSportlineType,
  } = useSportsListDateFilter({ dateTimeFilter: currentDateTimeFilter });

  async function onDateTimeFilterSelect(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void> {
    await reloadSportsTreeForFilter(filter);
  }

  async function onDateTimeFilterSelectCustomRange(range: DateTimeRange): Promise<void> {
    await reloadSportsTreeForFilter(range);
  }

  async function resetDateTimeFilter(): Promise<void> {
    await reloadSportsTreeForFilter(null);
  }

  return {
    dateTimeFilterRef,
    isSportFiltersEnabled,
    currentDateTimeFilter,
    datetimeActiveKickoffPeriod,
    datetimeActiveSportlineType,
    datetimeCustomFrom,
    datetimeCustomTo,
    onDateTimeFilterSelect,
    onDateTimeFilterSelectCustomRange,
    resetDateTimeFilter,
  };
}
